<template>
  <div class="outer-page">
    <div class="top-pane">
      <div class="top-left-pane">
        <span> 司机姓名： </span>
        <Input
          clearable
          placeholder="请输入司机姓名查询"
          style="margin-right: 10px; width: 240px"
          v-model="search_data.name"
        ></Input>
        <span> 电话： </span>
        <Input
          clearable
          placeholder="请输入司机电话查询"
          style="margin-right: 10px; width: 240px"
          v-model="search_data.phone"
        ></Input>
        <Button type="primary" style="margin-right: 10px" @click="oSearch">
          查询</Button
        >
        <Button @click="reset">重置</Button>
      </div>
      <div class="top-right-pane">
        <ul>
          <!-- acvtive -->
          <li
            v-for="(item, index) in statusList"
            :class="currentIndex == index ? '' : ''"
            :key="index"
            @click="changeStatus(index)"
          >
            {{ item.status }}：{{ statusList[index].val }}人
          </li>
          <!-- <li>在途司机：24人</li>
          <li>停业司机：24人</li> -->
        </ul>
      </div>
    </div>
    <div class="center-pane">
      <div class="center-top" ref="centerWidth">
        <div
          ref="itemWidth"
          class="center-pane-item"
          v-for="(item, index) in data"
          :key="index"
          :style="{ marginRight: (index + 1) % num == 0 ? '0' : '28px' }"
        >
          <div class="item-img">
            <img
              :src="
                item.img ? item.servicePath + item.img : '/imgs/driverLogo.jpg'
              "
              alt=""
            />
          </div>
          <div class="item-info">
            <div>{{ item.name }} | {{ item.sex == 1 ? "男" : "女" }}</div>
            <div style="color: #999; font-size: 12px">
              {{ item.phone }}
            </div>
            <div style="display: flex; justify-content: center">
              <span style="margin-right: 6px">{{ item.driverWorkStatus }}</span>
              <span
                class="iconfont icon-round round"
                :style="{
                  color:
                    item.driverWorkStatus == '空闲中'
                      ? '#06baa1'
                      : item.driverWorkStatus == '在途中'
                      ? '#F1B14F'
                      : '#99999A',
                }"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="center-footer">
        <Page
          :current="page.pageNo"
          :page-size="page.pageSize"
          :total="page.total"
          :show-elevator="true"
          :show-total="true"
          class="xpage"
          @on-change="pageChange"
        ></Page>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      widths: "",
      num: "",
      page: {
        total: 0,
        pageSize: 12,
        pageNo: 1,
      },
      data: [],
      search_data: {
        name: "",
        phone: "",
      },
      statusList: [
        { status: "空闲司机", val: 0 },
        { status: "在途司机", val: 0 },
        { status: "停业司机", val: 0 },
      ],
      currentIndex: 0,
    };
  },
  methods: {
    getList() {
      return this.$post(this.$api.DRIVER_MANAGE.LIST, {
        ...this.search_data,
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      }).then((res) => {
        this.data = res.list;
        res.list.forEach((item) => {
          if (item.driverWorkStatus == "空闲中") {
            this.statusList[0].val += 1;
          } else if (item.driverWorkStatus == "在途中") {
            this.statusList[1].val += 1;
          } else {
            this.statusList[2].val += 1;
          }
        });
        this.page.total = +Number(res.total);
        // console.log(this.data);
      });
    },
    oSearch() {
      this.getList();
    },
    reset() {
      this.search_data = {
        name: "",
        phone: "",
      };
    },
    changeStatus(index) {
      this.currentIndex = index;
    },
    resize() {
      // 获取div宽度
      this.widths = this.$refs.centerWidth.clientWidth;
      if (this.$refs.itemWidth[0]) {
        let i = this.$refs.itemWidth[0].clientWidth;
        let temp = (Math.floor(this.widths / i) - 1) * 28;
        this.num = Math.floor((this.widths - temp) / i);
      }
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
  },
  mounted() {
    this.getList().then(() => {
      this.resize();
    });
    // 监听屏幕变化
    window.addEventListener("resize", this.resize);
    this.$nextTick(() => {
      this.widths = this.$refs.centerWidth.clientWidth;
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resize);
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  // width: 100%;
  width: calc(~"100% - 4px");
  height: 100%;
  margin-left: 2px;
  margin-top: 2px;
  .top-pane {
    height: 80px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 4px 0 #ccc;
    display: flex;
    justify-content: space-between;
    .top-left-pane {
      padding: 20px;
      display: flex;
      justify-content: space-between;
      span {
        line-height: 30px;
      }
    }
    .top-right-pane {
      ul {
        display: flex;
        height: 100%;
        li {
          display: inline-block;
          height: 100%;
          line-height: 80px;
          padding: 0 20px;
          overflow: hidden;
          cursor: pointer;
        }
        .active {
          background-color: #06baa1;
          color: #fff;
        }
      }
    }
  }
  .center-pane {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    .center-top {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;
      padding-top: 2px;
      padding-left: 2px;
      .center-pane-item {
        width: 250px;
        height: 378px;
        background-color: #fff;
        box-shadow: 0 0 4px 0 #ccc;
        border-radius: 4px;
        margin-bottom: 28px;
        padding: 20px;
        .item-img {
          padding-top: 20px;
          width: 100%;
          height: 200px;
          text-align: center;
          img {
            overflow: hidden;
            border-radius: 50%;
            width: 160px;
            height: 160px;
          }
        }
        .item-info {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          font-weight: bold;
          font-size: 15px;
          height: 120px;
          div {
            text-align: center;
          }
          .icon-round {
            font-size: 12px;
            font-weight: bold;
          }
          .round {
            display: inline-block;
            margin-top: 2px;
          }
        }
      }
    }
    .center-footer {
      text-align: right;
    }
  }
}
</style>